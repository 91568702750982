// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import cryptoAccountsService from '../../../../app/services/cryptoAccounts';
// TYPES


export default ({
	getCryptoAccounts: createAsyncThunk('cryptoAccounts/getCryptoAccounts',
		async (customerID: string, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoAccountsService.getCryptoAccounts(customerID), thunkAPI, false)),
});