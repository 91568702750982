// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand"
import objectToURL from "../../helpers/objectToURL";
// TYPES
import {IGetBusinessContacts} from "../../store/reducers/business/index.D";


const {get, post, patch, delete: del} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	getBusinesses: (params?: { [key: string]: any }): Promise<string> => get(
		`${URL()}/business?${objectToURL(params)}`
	),
	getBusiness: (params: string): Promise<string> => get(
		`${URL()}/business/${params}`
	),
	getBusinessContacts: ({id, params}: IGetBusinessContacts): Promise<string> => get(
		`${URL()}/business/${id}/contacts?${objectToURL(params)}`
	),
	getBusinessContact: (contactId: string): Promise<string> => get(
		`${URL()}/business/contact/${contactId}`
	),
	updateBusiness: ({_id, ...params}: any): Promise<string> => patch(
		`${URL()}/business/${_id}`, {params}
	),
	updateRisk: ({businessId, risk}: any): Promise<string> => post(
		`${URL()}/business/${businessId}/risk`, {risk}
	),
	updateAddress: ({businessId, ...address}: any): Promise<string> => patch(
		`${URL()}/business/${businessId}/address`, {address}
	),
	updateStatus: ({id, status}: any): Promise<string> => post(
		`${URL()}/business/${id}/status`, {status}
	),
	deleteBusinessContact: (contactId: string): Promise<string> => del(
		`${URL()}/business/contact/${contactId}`
	),
	changeBusinessStatus: ({ businessId, status }: { businessId: string; status: number}) => 
		patch(`${URL()}/business/${businessId}/status`, { status }),
	getBusinessQuestionnaire: (businessId: string): Promise<any> =>
		get(`${URL()}/business/${businessId}/questionnaire`)
});
