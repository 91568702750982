// CORE
import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {ICryptoAccountsSlice} from './index.D';
// ACTIONS
import asyncActions from './asyncActions';
import {defaultPagination} from "../search/pagination.D";


const initialState: ICryptoAccountsSlice = {
	isLoading: false,
  items:     [],
};

const cryptoFeesMarginsSlice = createSlice({
	name:     'cryptoFeesMargins',
	initialState,
	reducers: {
		clearCryptoAccounts: (state) => {
			state.items = [];

			return state;
		},
	},
	extraReducers: {
		[asyncActions.getCryptoAccounts.pending.type]: (state) => {
			state.isLoading = true;
		},
    [asyncActions.getCryptoAccounts.rejected.type]: (state) => {
			state.isLoading = false;
		},
		[asyncActions.getCryptoAccounts.fulfilled.type]: (state, { payload }) => {
			state.isLoading = false;
      state.items = payload;
		},
	},
});

export default ({
	...cryptoFeesMarginsSlice,
	asyncActions
})