// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand";
// import objectToURL from "../../helpers/objectToURL";
// TYPES


const {get, delete: del, post, patch} = apiClient;
const URL = () => `organizations/${getBrand()}/crypto-accounts`;

const cryptoAccountsService = {
  getCryptoAccounts: (customerID: string) => get(`${URL()}/list/${customerID}`),
};

export default cryptoAccountsService;